import React from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"

const StyledFooter = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 350px;
  .footer-left,
  .footer-right {
    padding: 4rem;
    color: ${colors.white};
  }
  .footer-left {
    background: ${colors.black};
    h3 {
      font-size: 2.35rem;
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-bottom {
      p {
        font-size: 0.8rem;
        margin: 0;
        opacity: 0.7;
        margin-top: -1rem;
      }
    }
  }
  .footer-right {
    background: ${colors.darkGrey};
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    p {
      margin: 0;
      font-weight: 300;
      font-size: 1.12rem;
      opacity: 0.7;
      &.strong {
        opacity: 1;
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }
    }
  }
  .footer-left-bottom-mobile {
    display: none;
  }
  .bm-credit {
    background: ${colors.black};
    color: ${colors.white};
    grid-column: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    a {
      margin: 0.5rem;
      opacity: 0.7;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    .footer-right {
      grid-template-columns: repeat(2, 1fr);
      padding: 1.5rem;
      padding-bottom: 6rem;
      p {
        font-size: 1rem;
      }
    }
    .footer-left {
      padding: 1.5rem;
      padding-top: 4rem;
      padding-bottom: 1rem;
      background: ${colors.darkGrey};
      h3 {
        font-size: 1.6rem;
      }
    }
    .footer-left-bottom-desktop {
      display: none;
    }
    .footer-left-bottom-mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: ${colors.black};
      color: ${colors.white};
      padding: 2rem;
      padding-bottom: 3rem;
    }
    .bm-credit {
      grid-column: 1 / 2;
    }
  }
`

const Footer = () => {
  const footerLeftBottom = (
    <div className="footer-left-bottom">
      <img src="/images/logo-light.png" alt="logo" />
      <p>© {new Date().getFullYear()} Sharp4 Iceboards. All rights reserved.</p>
    </div>
  )
  return (
    <StyledFooter>
      <div className="footer-left">
        <h3>Tveka inte att höra av dig!</h3>
        <div className="footer-left-bottom-desktop">{footerLeftBottom}</div>
      </div>
      <div className="footer-right">
        <div className="footer-contact-container">
          <p className="strong">Kontakt</p>
          <p>Örkroken 2</p>
          <p>138 40 Älta</p>
          <br />
          <p>070 886 50 10</p>
          <p>Sharp4@live.se</p>
        </div>
        <div className="footer-contact-container">
          <p className="strong">Öppettider</p>
          <p>Vardagar 9-18</p>
          <p>Helgdagar 11-15</p>
        </div>
      </div>
      <div className="footer-left-bottom-mobile">{footerLeftBottom}</div>
      <div className="bm-credit">
        <a href="https://bucketmedia.se" target="__blank">
          Den här webbplatsen skapades av Bucket Media AB.
        </a>
      </div>
    </StyledFooter>
  )
}

export default Footer
