export const colors = {
  black: "#000",
  white: "#fff",
  offWhite: "#f9f9f9",
  lightBlue: "#00AEEF",
  darkGrey: "#1e1e1e",
}

export const db = "http://localhost:9000/.netlify/functions/index/"
// export const db = "https://temp-domain.netlify.app/.netlify/functions/index/"
