import React from "react"
import styled from "styled-components"

const StyledBtnNoStyle = styled.button`
  background: none;
  border: none;
  border-radius: 0;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  display: block;
  cursor: pointer;
  &:active,
  &:focus {
    outline: none;
  }
`

const BtnNoStyle = props => {
  return (
    <StyledBtnNoStyle
      onClick={props.onClick}
      className={props.className}
      id={props.id}
      type={props.type}
    >
      {props.children}
    </StyledBtnNoStyle>
  )
}

export default BtnNoStyle
