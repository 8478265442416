import React from "react"
import styled from "styled-components"

const StyledHamburger = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  div {
    position: absolute;
    height: 2px;
    background: ${props => props.color};
    transition: 350ms ease-in-out;
    &:nth-child(1) {
      width: 100%;
      top: 15%;
    }
    &:nth-child(2) {
      width: 100%;
      top: 50%;
    }
    &:nth-child(3) {
      width: 100%;
      top: 85%;
    }
  }
  &.active {
    div {
      &:nth-child(1) {
        transform: rotateZ(315deg);
        width: 120%;
        top: calc(50% - 1px);
      }
      &:nth-child(2) {
        width: 90%;
        top: 50%;
        transform: translateX(200px);
        opacity: 0;
      }
      &:nth-child(3) {
        transform: rotateZ(-315deg);
        width: 120%;
        top: calc(50% - 1px);
      }
    }
  }
`

const Hamburger = props => {
  return (
    <StyledHamburger
      color={props.color}
      className={props.active ? "active" : ""}
    >
      <div></div>
      <div></div>
      <div></div>
    </StyledHamburger>
  )
}

export default Hamburger
