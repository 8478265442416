import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"
import BtnNoStyle from "./btnNoStyle"
import Hamburger from "./hamburger"

const StyledNavbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  .nav-inner {
    position: absolute;
    z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
    .nav-left {
      margin-left: 4rem;
      img {
        transition: 200ms ease-in-out;
        width: 100px;
        opacity: 1;
        pointer-events: initial;
      }
    }
    .nav-right {
      margin-right: 4rem;
      margin-top: 1rem;
      a {
        margin-left: 1rem;
        font-size: 18px;
        position: relative;
        &:after {
          transition: 200ms ease-in-out;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 0;
          height: 1px;
          content: "";
          background: ${colors.black};
        }
        &:hover,
        &.current {
          &:after {
            width: 100%;
          }
        }
        &.current {
          font-weight: 700;
        }
      }
    }
  }
  .hamburger {
    display: none;
  }
  .nav-slideout {
    display: none;
  }
  @media (max-width: 768px) {
    .nav-inner {
      align-items: flex-start;
      padding: 0 1.5rem;

      .nav-left {
        margin-left: 0rem;
        img {
          ${props => (props.navOpen ? "opacity: 0; pointer-events: none;" : "")}
        }
      }
      .nav-right {
        opacity: 0;
        pointer-events: none;
      }
    }
    .hamburger {
      display: block;
      margin-top: 1rem;
    }
    .nav-slideout {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      position: absolute;
      top: 0;
      min-height: 100vh;
      width: 100vw;
      z-index: 9;
      background: ${colors.black};
      color: ${colors.white};
      padding: 2rem;
      transition: 400ms ease-in-out;
      opacity: 0;
      left: 100vw;
      .links-container {
        display: flex;
        flex-direction: column;

        justify-content: center;
        a {
          font-size: 1.62rem;
          line-height: 180%;
        }
      }
      .nav-footer {
        display: flex;
        p {
          margin: 0;
          font-weight: 300;
          font-size: 1.12rem;
          opacity: 0.7;
          &.strong {
            opacity: 1;
            font-size: 1.25rem;
            font-weight: 700;
            margin-bottom: 1rem;
          }
        }
      }
      &.open {
        opacity: 1;
        left: 0;
      }
    }
  }
`

const Navbar = props => {
  const links = [
    {
      text: "Lagning och reparation",
      to: "/reparation",
    },
    {
      text: "Beställ bräda",
      to: "/bestall",
    },
  ]

  const [navOpen, setNavOpen] = useState(false)

  return (
    <StyledNavbar navOpen={navOpen}>
      <div className="nav-inner">
        <div className="nav-left">
          <Link to="/">
            <img src="/images/logo.png" alt="logo" />
          </Link>
        </div>
        <div className="nav-right">
          {links.map(link => (
            <Link
              key={link.text}
              to={link.to}
              className={props.pathname === link.to ? "current" : ""}
            >
              {link.text}
            </Link>
          ))}
        </div>

        <BtnNoStyle className="hamburger" onClick={() => setNavOpen(!navOpen)}>
          <Hamburger
            color={navOpen ? colors.white : colors.black}
            active={navOpen}
          />
        </BtnNoStyle>
      </div>
      <div className={`nav-slideout ${navOpen ? "open" : ""}`}>
        <div className="links-container">
          {links.map(link => (
            <Link
              onClick={() => setNavOpen(false)}
              key={link.text}
              to={link.to}
              className={props.pathname === link.to ? "current" : ""}
            >
              {link.text}
            </Link>
          ))}
        </div>
        <div className="nav-footer">
          <div className="footer-contact-container">
            <p className="strong">Kontakt</p>
            <p>Örkroken 2</p>
            <p>138 40 Älta</p>
            <br />
            <p>070 886 50 10</p>
            <p>Sharp4@live.se</p>
          </div>
          <div className="footer-contact-container">
            <p className="strong">Öppettider</p>
            <p>Vardagar 9-18</p>
            <p>Helgdagar 11-15</p>
          </div>
        </div>
      </div>
    </StyledNavbar>
  )
}

export default Navbar
