import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Footer from "./footer"
import Navbar from "./navbar"
import "../utils/index.css"
import { colors } from "../utils/siteVars"

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${colors.offWhite};
  .app-inner {
    margin-top: 50px;
    flex-grow: 1;
    h1 {
      font-size: 4.5vw;
      line-height: 100%;
    }
    p {
      font-size: 1.5rem;
      font-weight: 300;
    }
  }
  .page {
    display: grid;
    grid-template-columns: 8rem 5fr 1fr;
    min-height: 100vh;
    overflow: hidden;
    .index-content {
      margin-top: -10vh;
      align-self: center;
      max-width: 40vw;
      transform: translateY(20%);
      opacity: 0;
      transition: 1.5s ease-in-out;
      h1 {
        margin: 0;
        margin-bottom: 2rem;
      }
      p {
        margin: 0;
        margin-bottom: 2.5rem;
      }
      .order-button {
        margin-top: 2rem;
      }
    }
  }

  @media (max-width: 768px) {
    .app-inner {
      h1 {
        font-size: 2.25rem;
      }
      p {
        font-size: 1rem;
      }
    }
    .page {
      grid-template-columns: 1.5rem 1fr auto;

      .index-content {
        margin-top: -25vh;
        max-width: calc(100% - 2rem);
      }
    }
  }
`

const Layout = props => {
  let currentLocation = ""

  if (typeof window !== `undefined`) {
    currentLocation = window.location.pathname
  }
  return (
    <>
      <Helmet>
        <title>{props.title ? props.title + " | " : ""}Sharp4 Iceboards</title>
        <meta
          name="description"
          content="Välkommen till Sharp4 Iceboards webbplats."
        ></meta>
        <link
          rel="icon"
          type="image/png"
          href="favicon-32x32.png"
          sizes="32x32"
        />

        <link
          rel="icon"
          type="image/png"
          href="favicon-16x16.png"
          sizes="16x16"
        />
      </Helmet>
      <StyledLayout>
        <Navbar pathname={currentLocation} />
        <div className="app-inner">{props.children}</div>
      </StyledLayout>
      <Footer />
    </>
  )
}

export default Layout
