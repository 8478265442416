import React from "react"
import styled from "styled-components"
import { colors } from "../utils/siteVars"

const StyledButton = styled.button`
  font-size: 1.15rem;
  padding: 1rem 2.7rem;
  color: ${colors.white};
  background: ${colors.lightBlue};
  border: none;
  font-family: inherit;
  border-radius: 2.5rem;
  &:active,
  &:focus {
    outline: none;
  }
`

const Button = props => {
  return (
    <StyledButton
      onClick={props.onClick}
      className={props.className}
      type={props.type}
      id={props.id}
    >
      {props.children}
    </StyledButton>
  )
}

export default Button
